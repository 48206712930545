<div class="notify-content">
    <div class="row">
        <div class="col-12 col-lg-3">
            <div class="status-msg d-flex justify-content-between">
                <div class="lida text-center" [ngClass]="{ 'selected-nao-lida': selectedStatus === 'Não lida' }"
                    (click)="selectStatus('Não lida')">
                    <label>Não lida <i class=" ms-2 fa-solid fa-envelope"></i></label>
                </div>
                <div class="nao_lida text-center" [ngClass]="{ 'selected-lida': selectedStatus === 'Lida' }"
                    (click)="selectStatus('Lida')">
                    <label>Lida <i class=" ms-2 fa-solid fa-envelope-open"></i></label>
                </div>
            </div>
            @if(notificacoes.length > 0){
            @for (notificacao of notificacoesPaginadas; track $index) {
            <div class="row notificacao" (click)="visualizarMensagem(notificacao)"
                [ngClass]="notificacao.id == this.mensagem.id ? 'selected' : ''">
                <div class="col-10 ">
                    <div class="text-truncate"><strong>{{notificacao.remetente?.nome}}</strong></div>
                    <div class="text-truncate">{{notificacao.assunto}}</div>
                    <div class="data">{{notificacao.dataCadastro | date : 'dd/MM/YYYY'}}</div>
                </div>
                <div class="col-2 icone-lixeira p-1 d-flex flex-column justify-content-between align-items-center">
                    @if(notificacao.status == 'Não lida'){
                    <i class="fa-solid fa-envelope"></i>
                    }@else{
                    <i class="fa-solid fa-envelope-open"></i>
                    <i class="fa-solid fa-trash-can text-danger"
                        (click)="excluirNotificacao(notificacao.id,notificacao.assunto!)"></i>
                    }
                </div>
            </div>
            }
            <app-paginator-sistemico [itens]="notificacoes" (itensPaginados)="receberItensPaginados($event)"
                [itemsPorPagina]="10" [ajustarQuantidadePorPagina]="false"></app-paginator-sistemico>
            }@else {
            <div class="text-center"><strong>Não há notificações {{selectedStatus === 'Não lida' ? 'Não lida' : 'Lida'}}</strong></div>
            }
        </div>
        <div class="col-12 col-lg-9 d-flex  justify-content-center ">
            <div class="assunto " [@slideUpToBottom]="animationState">
                @if(mensagem.assunto){
                <div class="header-notificaca">
                    <h4 class="mt-2">{{mensagem.assunto}}</h4>
                    <label><strong>Remetente: </strong>{{mensagem.remetente?.nome}}</label>
                </div>

                <div class="body-notificacao">
                    <div class="msg mt-2">
                        <p>{{mensagem.mensagem}}</p>
                    </div>
                    <div class="mt-5">
                        <a href="{{mensagem.url}}" target="_blank">Clique aqui para visualizar</a>
                    </div>
                </div>
                }@else {
                <span class="p-2 text-center">Selecione alguma notificação para ler</span>
                }
            </div>
        </div>
    </div>
</div>