import { NotificacaoDestinatario } from "./notificacao-destinatario";
import { NotificacaoRemetente } from "./notificacao-remetente";

export class NotificacaoSistemaConsulta{
    id:number = 0;
    assunto?:string;
    mensagem?:string;
    status?:string;
    url?:string;
    dataCadastro?:string;
    destinatario?: NotificacaoDestinatario;
    remetente?:NotificacaoRemetente;
}