import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { NotificacoesSistemaService } from '../../../../services/infra/notificacoes-sistema.service';
import { NotificacaoSistemaConsulta } from '../../../../model/infra/notificacoes/notificacao-consulta';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { EmisorEventoGeralService } from '../../../../utils/emisorEventoGeral.service';
import { slideUpToBottom } from '../../../../animations/animations';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../../../../componentes/modal/modal.component';
import { PaginatorSistemicoComponent } from "../../../../componentes/paginator-sistemico/paginator-sistemico/paginator-sistemico.component";

@Component({
  selector: 'app-consulta',
  standalone: true,
  imports: [
    CommonModule, FormsModule,
    PaginatorSistemicoComponent
  ],
  animations: [
    slideUpToBottom
  ],
  templateUrl: './consulta.component.html',
  styleUrl: './consulta.component.scss'
})
export class ConsultaComponent implements OnInit {

  notificacaoService = inject(NotificacoesSistemaService);
  dialog = inject(MatDialog);
  cdr = inject(ChangeDetectorRef);
  notificacoes: NotificacaoSistemaConsulta[] = [];
  mensagem: NotificacaoSistemaConsulta;
  selectedStatus: string = 'Não lida';
  animationState: string = 'recuando';
  public isMobile: boolean = false;
  notificacoesPaginadas:NotificacaoSistemaConsulta[] = [];

  constructor(

  ) {
    this.mensagem = new NotificacaoSistemaConsulta();
  }

  ngOnInit(): void {
    this.consultarNotificacoes();
    this.isMobile = window.matchMedia('(max-width: 878px)').matches;
    if (this.isMobile) {
      this.animationState = "enteringFromLeft";
    }
  }

  selectStatus(status: string): void {
    this.selectedStatus = status;
    this.limpar();
    this.consultarNotificacoes();
  }

  public consultarNotificacoes() {
    this.notificacaoService.consultarNotificacoesDoUsuario(this.selectedStatus).subscribe({
      next: (res) => {
        this.notificacoes = res;
      }, complete: () => {

      }
    });
  }
  public consultarNotificacoesNaoLida() {
    this.notificacaoService.consultarNotificacoesDoUsuario("Não lida").subscribe({
      next: (res) => {
        EmisorEventoGeralService.quantidadeNotificacao.emit(res.length);
      }, complete: () => {

      }
    });
  }

  public visualizarMensagem(notificacao: NotificacaoSistemaConsulta) {
    if (this.mensagem?.id !== notificacao.id) {
      console.log("mudou status")
      // Define o estado de recuo ou entrada dependendo do dispositivo
      const animationOut = this.isMobile ? 'enteringFromLeft' : 'recuando';
      this.animationState = animationOut;

      setTimeout(() => {
        this.mensagem = notificacao;
        this.animationState = this.isMobile ? 'leftToRight' : 'descendo';
      }, 250); // Tempo da animação de saída


      this.notificacaoService.mudarStatusParaLido(notificacao.id).subscribe({
        next: (res) => {

        }, error: (error) => {

        }, complete: () => {
          this.selectedStatus = "Lida";
          this.consultarNotificacoesNaoLida();
          this.consultarNotificacoes();

        }
      })
    }
  }

  public excluirNotificacao(idNotificacao: number, assunto: string) {
    let dialogRef = this.dialog.open(ModalComponent, {
      data: { nomeArquivo: assunto, texto: 'Deseja realmente excluir esta mensagem ?' },
      width: "100%",
      maxWidth: "800px",
      maxHeight: "650px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.deletarNotificacao(idNotificacao);
      }
    })
  }

  private deletarNotificacao(idNotificacao: number) {
    this.notificacaoService.excluirNotificacao(idNotificacao).subscribe({
      next: (res) => {
        console.log(res)
      }, error: (error) => {
        console.log(error)
      }, complete: () => {

      }
    })
  }

  receberItensPaginados(event: any) {

    this.notificacoesPaginadas = event;
  }


  limpar() {
    this.mensagem = new NotificacaoSistemaConsulta();
  }
}
